import revive_payload_client_4sVQNw7RlN from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_hAQZccAnsC from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_yVLowv6hDl from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/.nuxt/primevue-plugin.mjs";
import plugin_client_533RIki58s from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
import nuxt3_plugin_BdfRvWAAOY from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/node_modules/vue-yandex-maps/dist/plugins/nuxt3-plugin.js";
import plugin_tLmAP220nC from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/.nuxt/unocss.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import customoOfetch_zBJTuv7VXR from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/plugins/customoOfetch.ts";
import initPlaginClient_client_Nsw0alNzzD from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/plugins/initPlaginClient.client.ts";
import priceFormat_Cb2yhyBrV2 from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/plugins/priceFormat.ts";
import recaptcha_W4US4xmBV6 from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/plugins/recaptcha.js";
import setCanonical_Ok2TJU0Ur6 from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/plugins/setCanonical.ts";
import toast_ysMjUcU7eJ from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/plugins/toast.ts";
import websokets_TJnLJa6XVY from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/plugins/websokets.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  debug_hAQZccAnsC,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  primevue_plugin_egKpok8Auk,
  plugin_client_533RIki58s,
  nuxt3_plugin_BdfRvWAAOY,
  plugin_tLmAP220nC,
  unocss_MzCDxu9LMj,
  chunk_reload_client_UciE0i6zes,
  customoOfetch_zBJTuv7VXR,
  initPlaginClient_client_Nsw0alNzzD,
  priceFormat_Cb2yhyBrV2,
  recaptcha_W4US4xmBV6,
  setCanonical_Ok2TJU0Ur6,
  toast_ysMjUcU7eJ,
  websokets_TJnLJa6XVY
]