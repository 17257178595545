import { default as chronologyhmnxOH5DJlMeta } from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/pages/chronology.vue?macro=true";
import { default as helpgbCBNy8q4xMeta } from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/pages/help.vue?macro=true";
import { default as historyIObhhnkQ0iMeta } from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/pages/history.vue?macro=true";
import { default as indexcWgUx5rP74Meta } from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/pages/index.vue?macro=true";
import { default as menuJoxkSOnyfyMeta } from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/pages/menu.vue?macro=true";
import { default as reportso0joNuQqs3Meta } from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/pages/reports.vue?macro=true";
import { default as reviews0QPoiNUVZhMeta } from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/pages/reviews.vue?macro=true";
import { default as settingsrZdZvsMSZzMeta } from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/pages/settings.vue?macro=true";
import { default as workersqEv3J6xPrHMeta } from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/pages/workers.vue?macro=true";
export default [
  {
    name: chronologyhmnxOH5DJlMeta?.name ?? "chronology",
    path: chronologyhmnxOH5DJlMeta?.path ?? "/chronology",
    meta: chronologyhmnxOH5DJlMeta || {},
    alias: chronologyhmnxOH5DJlMeta?.alias || [],
    redirect: chronologyhmnxOH5DJlMeta?.redirect,
    component: () => import("/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/pages/chronology.vue").then(m => m.default || m)
  },
  {
    name: helpgbCBNy8q4xMeta?.name ?? "help",
    path: helpgbCBNy8q4xMeta?.path ?? "/help",
    meta: helpgbCBNy8q4xMeta || {},
    alias: helpgbCBNy8q4xMeta?.alias || [],
    redirect: helpgbCBNy8q4xMeta?.redirect,
    component: () => import("/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/pages/help.vue").then(m => m.default || m)
  },
  {
    name: historyIObhhnkQ0iMeta?.name ?? "history",
    path: historyIObhhnkQ0iMeta?.path ?? "/history",
    meta: historyIObhhnkQ0iMeta || {},
    alias: historyIObhhnkQ0iMeta?.alias || [],
    redirect: historyIObhhnkQ0iMeta?.redirect,
    component: () => import("/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/pages/history.vue").then(m => m.default || m)
  },
  {
    name: indexcWgUx5rP74Meta?.name ?? "index",
    path: indexcWgUx5rP74Meta?.path ?? "/",
    meta: indexcWgUx5rP74Meta || {},
    alias: indexcWgUx5rP74Meta?.alias || [],
    redirect: indexcWgUx5rP74Meta?.redirect,
    component: () => import("/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/pages/index.vue").then(m => m.default || m)
  },
  {
    name: menuJoxkSOnyfyMeta?.name ?? "menu",
    path: menuJoxkSOnyfyMeta?.path ?? "/menu",
    meta: menuJoxkSOnyfyMeta || {},
    alias: menuJoxkSOnyfyMeta?.alias || [],
    redirect: menuJoxkSOnyfyMeta?.redirect,
    component: () => import("/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/pages/menu.vue").then(m => m.default || m)
  },
  {
    name: reportso0joNuQqs3Meta?.name ?? "reports",
    path: reportso0joNuQqs3Meta?.path ?? "/reports",
    meta: reportso0joNuQqs3Meta || {},
    alias: reportso0joNuQqs3Meta?.alias || [],
    redirect: reportso0joNuQqs3Meta?.redirect,
    component: () => import("/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/pages/reports.vue").then(m => m.default || m)
  },
  {
    name: reviews0QPoiNUVZhMeta?.name ?? "reviews",
    path: reviews0QPoiNUVZhMeta?.path ?? "/reviews",
    meta: reviews0QPoiNUVZhMeta || {},
    alias: reviews0QPoiNUVZhMeta?.alias || [],
    redirect: reviews0QPoiNUVZhMeta?.redirect,
    component: () => import("/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: settingsrZdZvsMSZzMeta?.name ?? "settings",
    path: settingsrZdZvsMSZzMeta?.path ?? "/settings",
    meta: settingsrZdZvsMSZzMeta || {},
    alias: settingsrZdZvsMSZzMeta?.alias || [],
    redirect: settingsrZdZvsMSZzMeta?.redirect,
    component: () => import("/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: workersqEv3J6xPrHMeta?.name ?? "workers",
    path: workersqEv3J6xPrHMeta?.path ?? "/workers",
    meta: workersqEv3J6xPrHMeta || {},
    alias: workersqEv3J6xPrHMeta?.alias || [],
    redirect: workersqEv3J6xPrHMeta?.redirect,
    component: () => import("/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240930013109/pages/workers.vue").then(m => m.default || m)
  }
]